/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { Col, Row } from 'react-bootstrap';
import CPF from 'cpf-check';
import * as yup from 'yup';
import { FaRegIdBadge } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useStore } from 'react-context-hook';
import moment from 'moment';
import { Input } from '../../../components/Input';
import { ModalComponent } from '../../../components/Modal';
import { ISelect } from '../../../interfaces/ISelect';
import { GENDER, IUsuario, UFs } from '../../../interfaces/IUsuario';
import api from '../../../services/api';
import { ReactSelect } from '../../../components/ReactSelect';
import { Button } from '../../../components/Button';
import theme from '../../../styles/theme';
import { IEmpresa } from '../../../interfaces/IEmpresa';
import { ControlledReactSelect } from '../../../components/ReactSelect/controlled-select';
import { CheckAndUncheckBtn } from '../styles';
import { getCpfDummyEmail, unformatPhone, unformatCpf } from '../../../utils/shared-functions';
import { IGrupo } from '../../../interfaces/IGrupo';

// const mapGender = (genderResponse: number) => {
//   if (genderResponse === GENDER.MALE) {
//     return 'Masculino';
//   }
//   if (genderResponse === GENDER.FEMALE) {
//     return 'Feminino';
//   }
//   return 'Outro';
// };

const dataInitial: IUsuario = {
  id: 0,
  apelido: '',
  cargoUsuarioId: '',
  cpf: '',
  email: '',
  celular: '',
  empresaId: '',
  nome: '',
  perfilId: '',
  filialIds: [],
  unidadesIds: [],
  dataAdmissao: '',
  dataNascimento: '',
  sexo: { value: '', label: '' },
  uf: { value: '', label: '' },
  nomeSocial: '',
  matricula: '',
  unidadePrincipalId: { value: '', label: '' },
  filialPrincipalId: { value: '', label: '' },
};

const isValidDate = (date?: string): boolean => moment(date).isAfter(moment().subtract(150, 'y'));
// const validateBirthdayAndAdmission = (
//   birthday?: string,
//   admission?: string,
// ): boolean => moment(birthday)
//   .isBefore(moment(admission));

const schema = yup.object({
  nome: yup.string().required('Seu nome é obrigatório'),
  celular: yup.string().required('Campo obrigatório'),
  email: yup.string().email('Digite um email válido').required('Campo obrigatório'),
  cpf: yup
    .string()
    .required('Campo obrigatório')
    .test('cpf invalido', 'CPF Inválido', (value) => CPF.validate(value)),
  perfilId: yup.lazy((value) => (typeof value === 'object'
    ? yup.object().nullable().required('Campo obrigatório')
    : yup.string().required('Campo obrigatório'))),
  cargoUsuarioId: yup.lazy((value) => (typeof value === 'object'
    ? yup.object().nullable().required('Campo obrigatório')
    : yup.string().required('Campo obrigatório'))),
  filialIds: yup.array(),
  unidadesIds: yup.array(),
  dataNascimento: yup
    .string(),
  dataAdmissao: yup
    .string()
    .required('Campo obrigatório')
    .test('dataNascimento', 'Data de admissão inválida', (value) => isValidDate(value)),
  unidadePrincipalId: yup
    .mixed()
    .test('required', 'Campo obrigatório', (value) => value && (value > 0 || (value.value !== undefined && value.value !== null && value.value !== ''))),
  filialPrincipalId: yup
    .mixed()
    .test('required', 'Campo obrigatório', (value) => value && (value > 0 || (value.value !== undefined && value.value !== null && value.value !== ''))),
  // dataAdmissao: yup.lazy((dataAdmissao) => yup
  // .string()
  // .when(['dataNascimento'], (dataNascimento, schemaYup) => schemaYup.test(
  //   'dataAdmissao',
  //   'Data de admissão não pode ser menor que a data de nascimento',
  //   () => validateBirthdayAndAdmission(dataNascimento, dataAdmissao),
  // ))),
});

interface ModalFormProps {
  onCloseModal: () => void;
  childToParent: (cargos: any, grupos: any) => void;
  usuario?: IUsuario;
  onSetUsuarios: (usuario: IUsuario, update?: boolean) => void;
  showModal: boolean;
}

export const ModalForm = ({ onCloseModal, showModal, onSetUsuarios, usuario, childToParent }: ModalFormProps) => {
  const [perfis, setPerfis] = useState<ISelect[]>([]);
  const [grupos, setGrupos] = useState<ISelect[]>([]);
  const [cargos, setCargos] = useState<ISelect[]>([]);
  const [units, setUnits] = useState<ISelect[]>([]);
  const [loading, setLoading] = useState(false);
  const [company] = useStore<IEmpresa>('company');
  const [gender, setGender] = useState<any>({ label: '', value: '' });
  const [uf, setUf] = useState<ISelect>(usuario?.uf ?? { label: '', value: '' });
  const [escolaridade, setEscolaridade] = useState<ISelect>(
    usuario?.usuarioEscolaridadeId ?? { label: '', value: '' },
  );

  const [unidades, setUnidades] = useState<ISelect[]>([]);
  const [filias, setFilias] = useState<ISelect[]>([]);

  const sexoSelect: ISelect[] = [
    { label: 'Masculino', value: GENDER.MALE.toString() },
    { label: 'Feminino', value: GENDER.FEMALE.toString() },
    { label: 'Outro', value: GENDER.OTHER.toString() },
  ];

  const niveisEscolaridadeSelect: ISelect[] = [
    { label: 'Educação Infantil', value: '1' },
    { label: 'Fundamental', value: '2' },
    { label: 'Médio', value: '3' },
    { label: 'Superior (Graduação)', value: '4' },
    { label: 'Pós-Graduado', value: '5' },
    { label: 'Mestrado', value: '6' },
    { label: 'Doutorado', value: '7' },
  ];

  const ufSelect: ISelect[] = Array.from(UFs.entries(), ([key, value]) => ({
    label: value,
    value: key,
  }));

  // const cargosSelect: ISelect[] = Array.from(cargos.entries(), ([key, value]) => ({
  // label: value,
  // value: key,
  // }));

  useEffect(() => {
    api
      .get('usuario/perfis')
      .then(({ data }) => {
        const response = data.data.map((item: { id: number; nome: string }) => ({
          label: item.nome === 'Validacao' ? 'Validação' : item.nome,
          value: item.id,
        }));
        setPerfis(response.filter((item: { label: string; }) => item.label !== 'Sistema'));
      })
      .catch(() => toast.error('Erro ao buscar tipos de perfil'));

    api
      .get('filial')
      .then(({ data }) => {
        const filiaisData = data.data.map((item: { id: number; nome: string }) => ({
          label: `${item.id} - ${item.nome}`,
          value: item.id,
        }));

        const filteredFiliais = JSON.parse(JSON.stringify(filiaisData));

        setGrupos(filiaisData);
        setFilias(filteredFiliais);
      })
      .catch(() => {
        toast.error('Erro ao buscar lista de Grupos');
      });

    api
      .get('unidade-empresa')
      .then(({ data }) => {
        const unitsData = data.data.map((item: { id: number; nome: string }) => ({
          label: `${item.id} - ${item.nome}`,
          value: item.id,
        }));

        // eslint-disable-next-line max-len
        const filteredUnits = JSON.parse(JSON.stringify(unitsData));

        setUnits(unitsData);
        setUnidades(filteredUnits);
      })
      .catch(() => {
        toast.error('Erro ao buscar lista de Unidades');
      });

    setFilias(grupos);
  }, []);

  useEffect(() => {
    if (company) {
      api
        .get(`cargo/${company?.id}`)
        .then(({ data }) => {
          setCargos(
            data.data.map((item: { id: number; nome: string }) => ({
              label: `${item.id} - ${item.nome}`,
              value: item.id,
            })),
          );
        }).catch(() => toast.error('Erro ao buscar cargos!'));
    }
  }, [company]);

  useEffect(() => {
    childToParent(cargos, grupos);
  }, [cargos, grupos]);

  useEffect(() => {
    if (usuario) {
      const foundObject = sexoSelect.find((item) => item?.label === usuario?.sexo?.label);
      setGender(foundObject);
      setUf(usuario?.uf);
      setEscolaridade(niveisEscolaridadeSelect[usuario?.usuarioEscolaridadeId - 1]);
    }
  }, [usuario]);

  const getFiliaisOptions = (filiais: IGrupo[]) => filiais
    .map((p: { id: number; nome: string }) => ({
      value: p.id,
      label: p.nome,
    }));

  const removeDuplicates = (array: number[]) => {
    console.log('array', array);
    return array.filter((item, index) => array.indexOf(item) === index);
  };

  const handleDataAniversario = (dataNascimento?: string): string => {
    if (!dataNascimento || dataNascimento === '0001-01-01 00:00:00.000' || dataNascimento === '0000-12-31') {
      return '';
    }
    return dataNascimento;
  };

  const handlePrincipalId = (principalId: any, ids: number[], message: string): boolean => {
    let result = false;
    if (ids.includes(principalId)) {
      toast.error(message);
      setLoading(false);
      result = true;
    }
    return result;
  };

  const handleSubmit = useCallback(
    async (dataForm: IUsuario) => {
      try {
        setLoading(true);

        const unidadesIds = dataForm.unidadesIds.map((p) => +p.value).concat([]);
        const filialIds = dataForm.filialIds.map((p) => +p.value).concat([]);

        if (dataForm.unidadePrincipalId) {
          const unidadePrincipalIdValue = Number(dataForm.unidadePrincipalId.value ? dataForm.unidadePrincipalId.value : dataForm.unidadePrincipalId);
          if (handlePrincipalId(unidadePrincipalIdValue, unidadesIds, 'Unidade Principal não pode estar contida na lista de Unidades.')) {
            return;
          }
          // unidadesIds.push(unidadePrincipalIdValue);
        }

        if (dataForm.filialPrincipalId) {
          const filialPrincipalIdValue = Number(dataForm.filialPrincipalId.value ? dataForm.filialPrincipalId.value : dataForm.filialPrincipalId);
          if (handlePrincipalId(filialPrincipalIdValue, filialIds, 'Grupo Principal não pode estar contida na lista de Grupos.')) {
            return;
          }
          // unidadesIds.push(unidadePrincipalIdValue);
        }

        /*
        if (dataForm.unidadePrincipalId) {
          const unidadePrincipalIdValue = Number(dataForm.unidadePrincipalId.value ? dataForm.unidadePrincipalId.value : dataForm.unidadePrincipalId);
          if (unidadesIds.includes(unidadePrincipalIdValue)) {
            toast.error('Unidade Principal ID já está incluída nas Unidades Selecionadas.');
            setLoading(false);
            return;
          }
          unidadesIds.push(unidadePrincipalIdValue);
        }
        */

        // if (dataForm.filialPrincipalId) filialIds.push(Number(dataForm.filialPrincipalId?.value));

        const requestData = {
          ...dataForm,
          empresaId: company.id,
          email:
            dataForm.email !== '' ? dataForm.email : getCpfDummyEmail(unformatCpf(dataForm.cpf)),
          celular: unformatPhone(dataForm.celular),
          cpf: unformatCpf(dataForm.cpf),
          unidadesIds: removeDuplicates(unidadesIds),
          filialIds: removeDuplicates(filialIds),
          perfilId: dataForm.perfilId.value || dataForm.perfilId,
          sexo: +gender.value,
          uf: uf.value,
          cargoUsuarioId: dataForm.cargoUsuarioId?.value || dataForm.cargoUsuarioId,
          usuarioEscolaridadeId: dataForm.usuarioEscolaridadeId?.value,
          dataNascimento: handleDataAniversario(dataForm.dataNascimento),
          unidadePrincipalId: dataForm.unidadePrincipalId.value ? dataForm.unidadePrincipalId.value : dataForm.unidadePrincipalId,
          filialPrincipalId: dataForm.filialPrincipalId.value ? dataForm.filialPrincipalId.value : dataForm.filialPrincipalId,
        };

        if (usuario) {
          const { data } = await api.put(`usuario/${usuario.id}`, {
            ...requestData,
            cargoUsuarioId: dataForm.cargoUsuarioId.value || dataForm.cargoUsuarioId,
            usuarioEscolaridadeId: escolaridade?.value,
          });
          const formattedData = {
            ...data.data,
            filialIds: getFiliaisOptions(data.data.filiais),
          };

          onSetUsuarios(formattedData, true);
        } else {
          const { data } = await api.post('usuario', {
            ...requestData,
            cargoUsuarioId: dataForm.cargoUsuarioId.value,
            usuarioEscolaridadeId: escolaridade?.value,
          });

          const formattedData = {
            ...data.data,
            filialIds: getFiliaisOptions(data.data.filiais),
          };

          onSetUsuarios(formattedData);
        }

        onCloseModal();
        toast.success('Usuário salvo com sucesso');
      } catch (err: any) {
        if (err.response.data.errors[0] === 'Registro não encontrado.') {
          toast.error('Usuário com e-mail ou CPF já cadastrados.');
        } else if (err.response.data.errors) {
          const errors = err.response.data.errors.join(', ');
          toast.error(errors);
        } else {
          toast.error('Erro ao salvar usuário.');
        }
      } finally {
        setLoading(false);
      }
    },
    [uf?.value, gender?.value, onSetUsuarios, onCloseModal, usuario, company?.id, escolaridade],
  );

  return (
    <ModalComponent
      onClose={onCloseModal}
      modalVisible={showModal}
      title={usuario ? 'Atualizar Usuário' : 'Adicionar Usuário'}
      icon={FaRegIdBadge}
      size="lg"
    >
      <p>
        Cadastre aqui seus usuários. Eles somente conseguirão se cadastrar como &apos;&apos;Novo
        Jogador&apos;&apos; em sua plataforma gamificada se estiverem registrados aqui. O campo
        apelido é opcional.
      </p>
      <h6>Preencha todos os dados solicitados.</h6>

      <Formik
        initialValues={usuario || dataInitial}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {({ setFieldValue }) => (
          <Form>
            <Row>
              <Col md={6}>
                <Input type="text" name="nome" label="Nome" isRequerid />
              </Col>
              <Col md={6}>
                <Input type="text" name="apelido" label="Apelido" />
              </Col>
              <Col md={6}>
                <Input type="text" mask="999.999.999-99" name="cpf" label="CPF" isRequerid />
              </Col>
              <Col md={6}>
                <Input type="email" name="email" label="Email" isRequerid />
              </Col>
              <Col md={6}>
                <Input type="text" mask="(99) 99999-9999" name="celular" label="Celular" isRequerid />
              </Col>
              <Col md={6}>
                <Field
                  name="perfilId"
                  isRequerid
                  label="Tipo de perfil"
                  component={ReactSelect}
                  options={perfis}
                />
              </Col>
              <Col md={6}>
                <Field
                  name="filialIds"
                  label="Grupos (pode ser mais de 1)"
                  isMulti
                  component={ReactSelect}
                  options={grupos}
                />
                <CheckAndUncheckBtn
                  href="#"
                  onClick={() => setFieldValue('filialIds', grupos, false)}
                >
                  Marcar Todos
                </CheckAndUncheckBtn>
                <CheckAndUncheckBtn href="#" onClick={() => setFieldValue('filialIds', [], false)}>
                  Desmarcar Todos
                </CheckAndUncheckBtn>
              </Col>
              <Col md={6}>
                <Field
                  name="filialPrincipalId"
                  isRequerid
                  label="Grupo principal"
                  component={ReactSelect}
                  options={filias}
                />
              </Col>
              <Col md={6}>
                <ControlledReactSelect
                  label="Sexo"
                  options={sexoSelect}
                  value={gender}
                  onChange={(val) => setGender(val)}
                />
              </Col>
              <Col md={6}>
                <Field
                  name="cargoUsuarioId"
                  isRequerid
                  label="Cargo"
                  component={ReactSelect}
                  options={cargos}
                />
              </Col>
              <Col md={6}>
                <ControlledReactSelect
                  label="Nivel De Escolaridade"
                  options={niveisEscolaridadeSelect}
                  value={escolaridade}
                  onChange={(val) => setEscolaridade(val)}
                />
              </Col>
              <Col md={6}>
                <Input type="text" name="nomeSocial" label="Nome Social" />
              </Col>
              <Col md={6}>
                <Input type="text" name="matricula" label="Matricula" />
              </Col>
              <Col md={6}>
                <Field
                  name="unidadesIds"
                  label="Unidades (pode ser mais de 1)"
                  isMulti
                  component={ReactSelect}
                  options={units}
                />
                <CheckAndUncheckBtn
                  href="#"
                  onClick={() => setFieldValue('unidadesIds', units, false)}
                >
                  Marcar Todos
                </CheckAndUncheckBtn>
                <CheckAndUncheckBtn
                  href="#"
                  onClick={() => setFieldValue('unidadesIds', [], false)}
                >
                  Desmarcar Todos
                </CheckAndUncheckBtn>
              </Col>
              <Col md={6}>
                <Field
                  name="unidadePrincipalId"
                  isRequerid
                  label="Unidade principal"
                  component={ReactSelect}
                  options={unidades}
                />
              </Col>
              <Col md={6}>
                <Input type="date" name="dataAdmissao" label="Data Admissao" isRequerid />
              </Col>
              <Col md={6}>
                <Input type="date" name="dataNascimento" label="Data Nascimento" />
              </Col>
              <Col md={6}>
                <ControlledReactSelect
                  label="UF"
                  options={ufSelect}
                  value={uf}
                  onChange={(val) => {
                    setUf(val);
                  }}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={{ span: 4, offset: 4 }}>
                <Button
                  background={theme.colors.main}
                  color="#fff"
                  loading={loading}
                  className="text-uppercase"
                  type="submit"
                >
                  Salvar
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </ModalComponent>
  );
};
